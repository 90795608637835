import React from 'react'
import BookPlace from "../../../components/BookPlace/BookPlace"
import "./BlueClubCard.css"


function BlueClubCard({ blueclub, bookCover, alternativeText }) {
  return (
    
      <div className="card-container blue-stripe">
        <h1 className="card-title">{blueclub}</h1>
        <BookPlace image={bookCover} alternativeText={alternativeText} width={100} height={150}/>
        <button className="card-btn">اطلاعات بیشتر</button>
      </div>
  );
}

export default BlueClubCard