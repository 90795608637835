import "./Header.css";
// import Logo from "../../assets/bluelogo.svg";
import Logo2 from "../../assets/bluelogo2.svg";

import { NavLink } from "react-router-dom";

function Header() {
  
  return (
    <header>
      <nav>
        <NavLink to={"/"}>
          <img src={Logo2} alt="logo" className="logo" />
        </NavLink>

        <ul className="list">
          <li className="items">
            <NavLink to={"/about"} className="navigation-link">
              درباره ما
            </NavLink>
          </li>
          <li className="items">
            <NavLink to={"/gallery"} className="navigation-link">
              گالری
            </NavLink>
          </li>
          <li className="items">
            <NavLink to={"/"} className="navigation-link">
              خانه
            </NavLink>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
