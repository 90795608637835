/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import "./BookOverlay.css"; // Import your CSS file for BookOverlay styling

function BookOverlay({ onClose }) {
  return (
    <div className="overlay-book">
      <button className="close-button-book" onClick={onClose}>
        بستن
      </button>
      <p className="overlay-book-text-title">(بلوکلاب ۱۹)</p>
      <p className="overlay-book-text">شفافیت</p>
      <div className="overlay-content-book">
        <div className="iframe-container">
          <img
            src="https://media.giphy.com/media/EruZaPOld5NDhql2nX/giphy.gif"
            alt="Animated GIF"
            className="gif-image"
          />
        </div>
      </div>
    </div>
  );
}

export default BookOverlay;
