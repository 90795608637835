import React from "react";
import "./About.css";
import FormAbout from "./components/FormAbout";

function About() {
  return (
    <section className="about-section">
      <div className="about-first-container">
        <div className="image-container shine">
          <img
            src="https://res.cloudinary.com/dhfp1rz0p/image/upload/f_auto,q_auto/v1691002733/blueclub/Assets/hero_epdjum.png"
            alt="blue club"
            className="hero-image"
          />
        </div>
        <p className="about-text">
          بلوکلاب یک باشگاه کتابخوانیِ کاملاً رایگانه با ۱۰۰۰+ نفر عضو که در اون
          ما ماهی یک کتاب رو با اعضا میخونیم، از طریق ایمیل و تلگرام با هم در
          ارتباطیم و در مورد کتاب صحبت می‌کنیم. در انتهای همخوانی هم یک جلسه‌ی
          آنلاین و یک نشست حضوری داریم که کتاب رو بررسی می‌کنیم و البته کلی
          فعالیت گروهیِ جذاب دیگه.
        </p>
      </div>
      <div>
        <FormAbout />
      </div>
    </section>
  );
}

export default About;
