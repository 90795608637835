import React, { useEffect, useState } from "react";
import "./BabyYoda.css";
import BabyYodaP from "../../assets/baby-yoda.png";
import Modal from "../Modal/Modal";

function BabyYoda() {
  const [isVisible, setIsVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    // Update body overflow based on overlay visibility
    isModalOpen
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset");

    // Cleanup function to reset body overflow on unmount
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isModalOpen]);

  // Show Baby Yoda after 10 seconds when the component mounts or when the modal is closed
  useEffect(() => {
    const babyYodaTimeout = setTimeout(() => {
      setIsVisible(true);
    }, 10000);

    // Clean up the timeout on component unmount or when the modal is opened
    return () => clearTimeout(babyYodaTimeout);
  }, [isModalOpen]);

  const handleBabyYodaClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsVisible(false);
  };

  return (
    <>
      <div
        className={`baby-container ${isVisible ? "visible" : ""}`}
        onClick={handleBabyYodaClick}
      >
        <img
          src={BabyYodaP}
          alt="baby yoda"
          className={`baby ${isVisible ? "visible" : ""}`}
        />
      </div>
      {isModalOpen && <Modal onClose={handleCloseModal} />}
    </>
  );
}

export default BabyYoda;
