import { useState } from "react";
import "./FormAbout.css";

function FormAbout() {
  const [name, setName] = useState("");
  const [familyName, setFamilyName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(""); // Added state for message
  const [errorMessage, setErrorMessage] = useState("");

  const [result, setResult] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const translation = {
    "Email sent successfully!": "پیام شما با موفقیت ارسال شد",
    // Add other translations here as needed
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate the message length
    if (message.length < 10) {
      setErrorMessage("پیام باید حداقل ۱۰ کاراکتر باشد.");
      return; // Exit the submit function early if there's an error
    } else {
      setErrorMessage(""); // Clear the error message if the message is valid
    }

    setResult("در حال ارسال");
    setIsSubmitting(true); // Disable the submit button

    const apiUrl = process.env.REACT_APP_WEB3FORM_URL;
    const accessKey = process.env.REACT_APP_WEB3FORM_KEY;

    const formData = new FormData();
    formData.append("access_key", accessKey);
    formData.append("name", name);
    formData.append("familyName", familyName);
    formData.append("email", email); // Add the email field to the FormData
    formData.append("message", message);

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        body: formData,
      });

      const responseData = await response.json();

      if (responseData.success) {
        const translatedMessage =
          translation[responseData.message] || responseData.message;
        setResult(translatedMessage);
        setName(""); // Clear the form fields after a successful submit
        setFamilyName("");
        setEmail("");
        setMessage("");
      } else {
        setResult(responseData.message);
      }
    } catch (error) {
      setResult("خطا در ارسال. لطفا دوباره تلاش کنید");
    }

    setIsSubmitting(false); // Enable the submit button
  };

  return (
    <section className="form-section">
      <form onSubmit={handleSubmit} className="form">
        <h2 className="subscription-title">
          برای ارتباط جهت همکاری لطفا فرم زیر رو پر کنید{" "}
        </h2>
        <div className="input-container ic2">
          <label htmlFor="name" className="placeholder">
            نام
          </label>
          <input
            type="text"
            id="name"
            className="input"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="نام"
            required
          />
        </div>
        <div className="input-container ic1">
          <label htmlFor="familyName" className="placeholder">
            نام خانوادگی
          </label>
          <input
            type="text"
            id="familyName"
            className="input"
            placeholder="نام خانوادگی"
            value={familyName}
            onChange={(e) => setFamilyName(e.target.value)}
            required
          />
        </div>
        <div className="input-container ic2">
          <label htmlFor="email" className="placeholder">
            ایمیل
          </label>
          <input
            type="email"
            id="email"
            className="input"
            placeholder="ایمیل"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        {/* Textarea for the message */}
        <div className="input-container ic2">
          <label htmlFor="message" className="placeholder">
            متن پیام
          </label>
          <textarea
            id="message"
            className="input"
            placeholder="متن پیام"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />
        </div>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <button disabled={isSubmitting} className="submit" type="submit">
          ارسال پیام
        </button>
        <span className="form-submit-result">{result}</span>
      </form>
    </section>
  );
}

export default FormAbout;
