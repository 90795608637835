import './Home.css'

import BookHero from './components/BookHero/BookHero'
import Hero from './components/Hero/Hero'
import BlueGuysHero from './components/BlueGuysHero/BlueGuysHero';

function Home() {
  return (
    <div className='home'>
      <Hero />
      <BookHero />
      <BlueGuysHero />
    </div>
  );
}

export default Home