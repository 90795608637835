import React from "react";
import "./Modal.css";
import { FaYoutube, FaTwitter, FaInstagram, FaTelegram } from "react-icons/fa";

function Modal({ onClose }) {
  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-img-container">
          <img
            src="https://res.cloudinary.com/dhfp1rz0p/image/upload/f_auto,q_auto/v1691002733/blueclub/Assets/hero_epdjum.png"
            alt="blue club"
            className="modal-image"
          />
        </div>
        <h1 className="modal-head">Read. Connect. Discuss.</h1>
        <p className="modal-text">یادتون نره منو دنبال کنید</p>
        <div className="socials-modal">
          <a
            href="https://www.youtube.com/@poceansblue"
            target="_blank"
            rel="noreferrer"
          >
            <FaYoutube className="social-icon youtube-icon" />
          </a>
          <a
            href="https://www.instagram.com/poceansblue/"
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram className="social-icon instagram-icon" />
          </a>
          <a
            href="https://twitter.com/poceansblue"
            target="_blank"
            rel="noreferrer"
          >
            <FaTwitter className="social-icon twitter-icon" />
          </a>
          <a href="https://t.me/blueclub18" target="_blank" rel="noreferrer">
            <FaTelegram className="social-icon telegram-icon" />
          </a>
        </div>
        <button className="close-btn" onClick={onClose}>
          بستن
        </button>
      </div>
    </div>
  );
}

export default Modal;
