import React from "react";
import { Book3D } from "../Book3D/Book3D";

import "./BookPlace.css";

function BookPlace({ image, alternativeText, width, height }) {
  

  

  return (
    <section className="book-place-section">
      <div className="book-place">
        <Book3D
          bookCover={image}
          alternativeText={alternativeText}
          width={width}
          height={height}
        />
      </div>
      
    </section>
  );
}

export default BookPlace;
