import React from "react";
import "./Subscription.css";
import Form from "./Form/Form";

function Subscription() {
  return <section className="subscription-section">
    {/* <h1 className="subscription-title">عضویت رایگان در بلوکلاب</h1> */}
    <Form />
  </section>;
}

export default Subscription;
