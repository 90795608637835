import React, { useState } from "react";
import "./Accordion.css"

const toFarsiNumber = (number) => {
  const farsiDigits = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
  return String(number)
    .split("")
    .map((digit) => farsiDigits[parseInt(digit)])
    .join("");
};

const Accordion = ({ homeworks }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const handleAccordionClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="button-accordion">
      <button onClick={handleAccordionClick} className="show-button">
        تکالیف
      </button>
      {isExpanded && (
        <div className="button-container">
          {homeworks.length > 0 ? (
            homeworks.map((homework, index) => (
              <a href={homework} key={index} className="accordion-button" target="_blank" rel="noreferrer">
               مشق {toFarsiNumber(index + 1)}
              </a>
            ))
          ) : (
            <p className="no-homework-message">
              مشق نداشتیم!
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default Accordion;
