import React from 'react'
import SadYoda from "../../assets/sadyoda.png"
import "./NotFound.css"

function NotFound() {
  return (
    <section className='not-section'>
      <div className='not-container'>
        <h1 className='not-head'>404</h1>
        <img className='not-image' src={SadYoda} alt="baby yoda" />
      </div>
      <p className='not-text'>صفحه مورد نظر پیدا نشد!</p>
    </section>
  );
}

export default NotFound