import "./BookHero.css";
import React, { useState, useEffect } from "react";

import BookPlace from "../../../../components/BookPlace/BookPlace";

import { FaCopy } from "react-icons/fa";
import Data from "../../../../blueclubs.json";
import BookOverlay from "../../../../components/BookOverlay/BookOverlay";

function BookHero() {
  // eslint-disable-next-line no-unused-vars
  const [discountCode, setDiscountCode] = useState("Blueclub19");
  const [isCopied, setIsCopied] = useState(false);
  const [overlayVisible, setOverlayVisible] = useState(false);

  const lastBook = Data[Data.length - 1];

   useEffect(() => {
     // Update body overflow based on overlay visibility
     overlayVisible
       ? (document.body.style.overflow = "hidden")
       : (document.body.style.overflow = "unset");

     // Cleanup function to reset body overflow on unmount
     return () => {
       document.body.style.overflow = "unset";
     };
   }, [overlayVisible]);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(discountCode);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };

  const handleOverlayToggle = () => {
    setOverlayVisible(!overlayVisible);
  };
  return (
    <section className="book-hero-section">
      <div className="book-hero-container-one">
        <h1 className="book-hero-title align-right">{lastBook.blueclub}</h1>
        <div className="book-overlay-point" onClick={handleOverlayToggle}>
          <BookPlace
            image={lastBook.bookCover}
            alternativeText={lastBook.alternativeText}
          />
        </div>
        {overlayVisible && <BookOverlay onClose={handleOverlayToggle} />}

        <div className="book-btns">
          <div className="book-hero-discount">
            {isCopied && <p className="copy-message">کپی شد</p>}
            <FaCopy className="copy-icon" onClick={handleCopyToClipboard} />

            <p className="discount-code-container">
              <span
                className="discount-code"
              >
                {lastBook.discountCodes}
              </span>{" "}
              کد تخفیف
            </p>
          </div>

          <a
            href={lastBook.buyURL}
            className="buy-button"
            target="_blank"
            rel="noreferrer"
          >
            خرید آنلاین
          </a>
          {/* <p className="discount-text">
            ۲۰ درصد تخفیف خرید حضوری از شعبه کریمخان نشر چشمه{" "}
          </p> */}
        </div>
      </div>
      <div className="book-hero-container-two">
        <h1 className="book-hero-title align-right">کتاب این ماه</h1>
        <div className="book-hero-details">
          <div className="book-detail">
            <h3 className="book-detail-title">نام کتاب:</h3>
            <p className="book-detail-content">{lastBook.bookName}</p>
          </div>
          <div className="book-detail">
            <h3 className="book-detail-title">نویسنده:</h3>
            <p className="book-detail-content">{lastBook.author}</p>
          </div>
          {!!lastBook.translator && (
            <div className="book-detail">
              <h3 className="book-detail-title">مترجم:</h3>
              <p className="book-detail-content">{lastBook.translator}</p>
            </div>
          )}

          <div className="book-detail">
            <h3 className="book-detail-title">انتشارات:</h3>
            <p className="book-detail-content">{lastBook.publisher}</p>
          </div>
          <div className="book-detail book-summary">
            <p className="book-detail-content-summary">{lastBook.summary}</p>
            <h3 className="book-detail-title-summary">خلاصه کتاب</h3>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BookHero;
