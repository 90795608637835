import './BlueGuysHero.css'
import BlueGuysCardHero from './components/BlueGuysCardHero';

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";

import Data from "../../../../blueclubs.json"

function Hero() {
  const reversedData = Data.slice(0, -1).reverse();
  return (
    <section className="blueguys-hero-section">
      <h1 className="blueguys-hero-title">بلوکلاب‌های گذشته</h1>
      <Swiper
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Autoplay]}
        className="mySwiper"
        loop={true} // Enable loop
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        breakpoints={{
          1025: {
            slidesPerView: 7,
            spaceBetween: 10,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 10,
          },
          // For tablets and larger screens
          768: {
            slidesPerView: 3,
          },
          // For mobile devices
          320: {
            slidesPerView: 2,
          },
        }}
      >
        {reversedData.map((item) => (
          <SwiperSlide key={item.id}>
            <BlueGuysCardHero
              imageUrl={item.bookCover}
              alternativeText={item.alternativeText}
              slug={item.slug}
              id={item.id}
              blueclub={item.blueclub}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
}

export default Hero