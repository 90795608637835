import React from 'react'
import "./Hero.css"
import Welcome from "./components/Welcome/Welcome"
import Subscription from "./components/Subscription/Subscription"

function Hero() {
  return (
    <section className="hero-page-section">
      <div className="hero-item">
        <Subscription />
      </div>

      <div className="hero-item">
        <Welcome />
      </div>
    </section>
  );
}

export default Hero