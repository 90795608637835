import React from 'react'
import "./BlueGuysCardHero.css";
import { Link, useNavigate } from "react-router-dom";

function BlueGuysCardHero({imageUrl, alternativeText , slug ,id ,blueclub}) {

  const navigate = useNavigate();

  const handleClick = () => {
    // Scroll to the top of the page when the link is clicked
    window.scrollTo(0, 0);

    // Navigate to the specified route using the `navigate` function
    navigate(`/${id}/${slug}`);
  };

  return (
    <div className="blueguys-card-hero-container">
      <Link to={`/${id}/${slug}`} onClick={handleClick}>
        <img
          src={imageUrl}
          alt={alternativeText}
          className="blueguys-card-hero-image"
        />
        <p className="book-tag">{blueclub}</p>
      </Link>
    </div>
  );
}

export default BlueGuysCardHero