import React, { useState } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import Confetti from "react-confetti";
import "./Form.css";

function Form() {
  const [name, setName] = useState("");
  const [familyName, setFamilyName] = useState("");
  const [email, setEmail] = useState("");
  const [showConfetti, setShowConfetti] = useState(false);

  const handleSubmit = (subscribe, event) => {
    event.preventDefault();
   const formData = new FormData(event.target);
   const submittedEmail = formData.get("email");
   subscribe({ EMAIL: submittedEmail });

    setEmail("");
    setName("");
    setFamilyName("");
    setShowConfetti(true);
    setTimeout(() => setShowConfetti(false), 8000);
  };

  return (
    <section className="form-section">
      <MailchimpSubscribe
        url={process.env.REACT_APP_MAILCHIMP_URL}
        render={({ subscribe, status, message }) => (
          <>
            {showConfetti && <Confetti numberOfPieces={200} />}
            <form
              onSubmit={(event) => handleSubmit(subscribe, event)}
              className="form"
            >
              <h2 className="subscription-title">عضویت رایگان در بلوکلاب</h2>
              <h3 className="subtitle">برای عضویت ثبت نام کنید </h3>

              <div className="input-container ic2">
                <label htmlFor="FName" className="placeholder">
                  نام
                </label>
                <input
                  type="text"
                  id="FName"
                  name="FName" // Add the name attribute
                  className="input"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="نام"
                  required
                />
              </div>
              <div className="input-container ic1">
                <label htmlFor="LName" className="placeholder">
                  نام خانوادگی
                </label>
                <input
                  type="text"
                  id="LName"
                  name="LName" // Add the name attribute
                  className="input"
                  placeholder="نام خانوادگی"
                  value={familyName}
                  onChange={(e) => setFamilyName(e.target.value)}
                  required
                />
              </div>
              <div className="input-container ic2">
                <label htmlFor="email" className="placeholder">
                  ایمیل
                </label>
                <input
                  type="email"
                  id="email"
                  name="email" // Add the name attribute
                  className="input"
                  placeholder="ایمیل"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <button className="submit" type="submit">
                ثبت نام
              </button>
              {status === "sending" && (
                <div className="subscribe-form-status-loading">
                در حال ثبت نام؛ برای ثبت نام از وی‌پی‌ان استفاده کنید
                </div>
              )}
              {status === "error" && (
                <div className="subscribe-form-status-error">{message}</div>
              )}
              {status === "success" && (
                <div className="subscribe-form-status-success">
                  ثبت نام با موفقیت انجام شد
                </div>
              )}
            </form>
          </>
        )}
      />
    </section>
  );
}

export default Form;
