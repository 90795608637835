import { BookCover } from "book-cover-3d";


export const Book3D = ({bookCover , alternativeText , width , height}) => {
  return (
    <BookCover width={width} height={height}>
      <img src={bookCover} alt={alternativeText} />
    </BookCover>
  );
};
